import { RootState } from '..'
import { deleteZone, editSite, getCatalogs, getPlaylists, getSites, getThematics } from '../../Actions/playerSettings'
import { SaveZone, SiteWithParams, ZonePlayers } from '../../Components/Players/Model/PlayersModel'
import { Catalog, MyPlaylistResponse, Thematic } from '../../Mesvideo/models/MesvideoModel'
import { FetchStatusType } from '../storeType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const defautValueSite = {
    id: 0,
    hasPlaylists: false,
    mdtk: '',
    label: '',
    zones: [],
    settings: {
        themes: {
            light: {
                backgroundColor: '',
                primaryColor: '',
                secondaryColor: ''
            },
            dark: {
                backgroundColor: '',
                primaryColor: '',
                secondaryColor: ''
            }
        }
    }
}

interface playersSate {
    sites: SiteWithParams[]
    site: SiteWithParams
    statusGetSites: FetchStatusType
    updateSite: SiteWithParams
    statusEdit: FetchStatusType
    zones: ZonePlayers[]
    statusDelete: FetchStatusType
    lightTheme: boolean
    darkTheme: boolean
    colorBg: string
    colorPrimary: string
    colorSecondary: string
    darkColorBg: string
    darkColorPrimary: string
    darkColorSecondary: string
    menuExpanded: boolean
    statusThematics: FetchStatusType
    thematics: Thematic[]
    catalogs: Catalog[]
    randomChecked: boolean
    zone: string
    rangeSearch: number
    playlist: string
    catalogsSelected: Catalog[]
    playlists: MyPlaylistResponse
    zonesEdited: ZonePlayers[]
    saveZone: SaveZone[]
    isEdit: boolean
    refetch: number
    afterEdit: number
    isDisabled: boolean
    expanded: boolean
}

const initialState: playersSate = {
    sites: [],
    statusGetSites: FetchStatusType.IDLE,
    updateSite: defautValueSite,
    statusEdit: FetchStatusType.IDLE,
    zones: [],
    statusDelete: FetchStatusType.IDLE,
    site: defautValueSite,
    lightTheme: false,
    darkTheme: true,
    colorBg: '#000000',
    colorPrimary: '#2F45B5',
    colorSecondary: '#FFFFFF',
    menuExpanded: true,
    darkColorBg: '#000000',
    darkColorPrimary: '#2F45B5',
    darkColorSecondary: '#FFFFFF',
    statusThematics: FetchStatusType.IDLE,
    thematics: [],
    catalogs: [],
    randomChecked: false,
    zone: 'last',
    rangeSearch: 0,
    playlist: '',
    catalogsSelected: [],
    playlists: {
        data: [],
        metadata: {
            page: 0,
            results_per_page: 0,
            total: 0
        }
    },
    zonesEdited: [],
    saveZone: [],
    isEdit: false,
    refetch: 0,
    afterEdit: 0,
    isDisabled: false,
    expanded: false
}

export const playersSlice = createSlice({
    name: 'players',
    initialState,
    reducers: {
        setZones(state, action: PayloadAction<ZonePlayers[]>) {
            return { ...state, zones: action.payload }
        },
        setRefetch(state, action: PayloadAction<number>) {
            return { ...state, refetch: action.payload }
        },
        setSite(state, action: PayloadAction<SiteWithParams>) {
            return { ...state, site: action.payload }
        },
        setLightTheme(state, action: PayloadAction<boolean>) {
            return { ...state, lightTheme: action.payload }
        },
        setDarkTheme(state, action: PayloadAction<boolean>) {
            return { ...state, darkTheme: action.payload }
        },
        setBgColor(state, action: PayloadAction<string>) {
            return { ...state, colorBg: action.payload }
        },
        setPrimaryColor(state, action: PayloadAction<string>) {
            return { ...state, colorPrimary: action.payload }
        },
        setSecondaryColor(state, action: PayloadAction<string>) {
            return { ...state, colorSecondary: action.payload }
        },
        setMenuExpanded(state, action: PayloadAction<boolean>) {
            return { ...state, menuExpanded: action.payload }
        },
        setDarkBgColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorBg: action.payload }
        },
        setDarkPrimaryColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorPrimary: action.payload }
        },
        setDarkSecondaryColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorSecondary: action.payload }
        },
        setRandomChecked(state, action: PayloadAction<boolean>) {
            return { ...state, randomChecked: action.payload }
        },
        setModeZone(state, action: PayloadAction<string>) {
            return { ...state, zone: action.payload }
        },
        setRangeSearch(state, action: PayloadAction<number>) {
            return { ...state, rangeSearch: action.payload }
        },
        setPlaylist(state, action: PayloadAction<string>) {
            return { ...state, playlist: action.payload }
        },
        setCatalogsSelected(state, action: PayloadAction<Catalog[]>) {
            return { ...state, catalogsSelected: action.payload }
        },
        setDefaultZone(state, action: PayloadAction<ZonePlayers[]>) {
            return { ...state, zonesEdited: action.payload }
        },
        setZoneSettings(state, action: PayloadAction<{ zoneId: any; newZone: ZonePlayers }>) {
            const { zoneId, newZone } = action.payload
            const updatedZones = state.zonesEdited.map(zone => {
                if (zone.id === zoneId) {
                    return newZone
                }
                return zone
            })
            return { ...state, zonesEdited: updatedZones }
        },
        setSaveZone(state, action: PayloadAction<SaveZone[]>) {
            return { ...state, saveZone: action.payload }
        },
        setIsEdit(state, action: PayloadAction<boolean>) {
            return { ...state, isEdit: action.payload }
        },
        setIsDisabled(state, action: PayloadAction<boolean>) {
            return { ...state, isDisabled: action.payload }
        },
        setExpanded(state, action: PayloadAction<boolean>) {
            return { ...state, expanded: action.payload }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSites.pending, state => {
                return { ...state, statusGetSites: FetchStatusType.LOADING }
            })
            .addCase(getSites.fulfilled, (state, action) => {
                return { ...state, statusGetSites: FetchStatusType.COMPLETED, sites: action.payload }
            })
            .addCase(getSites.rejected, state => {
                return { ...state, statusGetSites: FetchStatusType.FAILED }
            })
        builder
            .addCase(editSite.pending, state => {
                return { ...state, statusEdit: FetchStatusType.LOADING }
            })
            .addCase(editSite.fulfilled, (state, action) => {
                const siteModify = action.payload;
                const updatedSites = state.sites.map(site => {
                    if (site.id === siteModify.id) {
                        return siteModify
                    }
                    return site
                })

                return {
                    ...state,
                    statusEdit: FetchStatusType.COMPLETED,
                    updateSite: action.payload,
                    site: action.payload,
                    sites: updatedSites,
                    afterEdit: 1
                }
            })
            .addCase(editSite.rejected, state => {
                return { ...state, statusEdit: FetchStatusType.FAILED }
            })
        builder
            .addCase(deleteZone.pending, state => {
                return { ...state, statusDelete: FetchStatusType.LOADING }
            })
            .addCase(deleteZone.fulfilled, (state, action) => {
                return { ...state, statusDelete: FetchStatusType.COMPLETED, zones: action.payload }
            })
            .addCase(deleteZone.rejected, state => {
                return { ...state, statusDelete: FetchStatusType.FAILED }
            })
        builder
            .addCase(getThematics.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getThematics.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, thematics: action.payload }
            })
            .addCase(getThematics.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
        builder
            .addCase(getCatalogs.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getCatalogs.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, catalogs: action.payload }
            })
            .addCase(getCatalogs.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
        builder
            .addCase(getPlaylists.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getPlaylists.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, playlists: action.payload }
            })
            .addCase(getPlaylists.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
    }
})

export const {
    setZones,
    setSite,
    setDarkTheme,
    setLightTheme,
    setBgColor,
    setPrimaryColor,
    setDarkBgColor,
    setDarkPrimaryColor,
    setDarkSecondaryColor,
    setSecondaryColor,
    setRandomChecked,
    setMenuExpanded,
    setModeZone,
    setRangeSearch,
    setPlaylist,
    setCatalogsSelected,
    setDefaultZone,
    setZoneSettings,
    setSaveZone,
    setIsEdit,
    setIsDisabled,
    setExpanded
} = playersSlice.actions

export const site = (state: RootState) => state.players.site

export const listSites = (state: RootState) => state.players.sites

export const siteUpdated = (state: RootState) => state.players.updateSite

export const listeZones = (state: RootState) => state.players.zones

export const lightTheme = (state: RootState) => state.players.lightTheme

export const darkTheme = (state: RootState) => state.players.darkTheme

export const colorBg = (state: RootState) => state.players.colorBg

export const colorPrimary = (state: RootState) => state.players.colorPrimary

export const colorSecondary = (state: RootState) => state.players.colorSecondary

export const darkColorBg = (state: RootState) => state.players.darkColorBg

export const darkColorPrimary = (state: RootState) => state.players.darkColorPrimary

export const darkColorSecondary = (state: RootState) => state.players.darkColorSecondary

export const menuExpanded = (state: RootState) => state.players.menuExpanded

export const thematicLists = (state: RootState) => state.players.thematics

export const catalogLists = (state: RootState) => state.players.catalogs

export const checkedRandom = (state: RootState) => state.players.randomChecked

export const zoneMode = (state: RootState) => state.players.zone

export const rangeSearch = (state: RootState) => state.players.rangeSearch

export const playlist = (state: RootState) => state.players.playlist

export const catalogsSelected = (state: RootState) => state.players.catalogsSelected

export const playlistLists = (state: RootState) => state.players.playlists

export const zonesEdited = (state: RootState) => state.players.zonesEdited

export const zoneSave = (state: RootState) => state.players.saveZone

export const getStatusSite = (state: RootState) => state.players.statusGetSites

export const isEdit = (state: RootState) => state.players.isEdit

export const isDisabledButton = (state: RootState) => state.players.isDisabled

export const statusEdit = (state: RootState) => state.players.statusEdit

export const refetch = (state: RootState) => state.players.refetch

export const afterEdit = (state: RootState) => state.players.afterEdit

export const expanded = (state: RootState) => state.players.expanded

export default playersSlice.reducer
