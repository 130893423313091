import { createAsyncThunk } from "@reduxjs/toolkit"
import { PlayersService } from "../Components/Players/Service/PlayersService"
import { RequestBodyPlayers } from "../Components/Players/Model/PlayersModel"
import { MesvideoService } from "../Mesvideo/services/MesvideoService"

const playersService = new PlayersService()
const videoService = new MesvideoService()

interface body {
    id: string
    params: RequestBodyPlayers
}

export const getSites = createAsyncThunk('players/listSites', async (format: string) => {
    const sites = await playersService.getSitesWithParams(format)
    return sites
})

export const editSite = createAsyncThunk('players/update', async (params: body) => {
    const company = await playersService.editSite(params.id, params.params)
    if (company === null) {
        throw new Error("editSite returned null")
    }
    return company
})

export const deleteZone = createAsyncThunk('players/delete', async (id: string) => {
    const zone = await playersService.deleteZone(id)
    if (zone === null) {
        throw new Error("delete zone returned null")
    }
    return zone
})

export const getThematics = createAsyncThunk('players/thematics', async () => {
    const thematics = await videoService.getThematique()
    return thematics
})

export const getCatalogs = createAsyncThunk('players/catalogs', async () => {
    const catalogs = await videoService.getCatalogue()
    return catalogs
})

export const getPlaylists = createAsyncThunk('players/playlists', async () => {
    const catalogs = await videoService.getMyPlaylists({})
    return catalogs
})