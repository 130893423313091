import { ILiveConfig } from '../../live/constant/liveType'
import { Site } from '../../Monetization/services/MonetizationService'
import { EVideoType } from '../constant/VideoType'

export type ExternalPlaylistProvider = 'facebook' | 'twitter' | 'dailymotion' | 'youtube'

type GenericExternPlatformState<T extends ExternalPlaylistProvider> = Record<T, boolean> & {
    channelID: string
}

export type ExternalPlatformState =
    | GenericExternPlatformState<'dailymotion'>
    | GenericExternPlatformState<'facebook'>
    | GenericExternPlatformState<'youtube'>
    | GenericExternPlatformState<'twitter'>

export type GetCatalogExternalPlaylistResponse = {
    external_platform_state: ExternalPlatformState[]
    externPlayLists: ExternPlaylist[]
}

export enum ETest {
    ENCODING_PROGRESS = 'encoding_progress'
}

export enum EncodedStatus {
    NOTHING = 0,
    LOADING = 1,
    FINISHED = 2,
    ERROR = 3
}

export interface VideoResponse {
    data: Video[]
    metadata: ResultMetadata
}

export interface IExternaPlaylistState {
    state: boolean
    catalogId: string
    channelID: string
}
export interface Video {
    id: string
    createdAt: Date
    updatedAt: Date
    userId: number
    title: string
    disponibility: number
    streams: number
    videoPath: string
    broadcastStart: Date | null
    broadcastEnd: Date | null
    description: string
    placeId: string
    duration: number
    lang: string
    externURL: string
    showAds: boolean
    ratingCSA: string
    refID: string
    externalID: string
    originalLang: string | null
    haveRights: string | null
    distributor: string
    otherAuthors: string
    enableOverlay: boolean
    displayText: string
    redirectURL: string
    startAt: number
    endAt: number
    textColor: string
    height: number
    pubOnYoutube?: IExternaPlaylistState
    pubOnDailymotion?: IExternaPlaylistState
    pubOnFacebook?: IExternaPlaylistState
    pubOnTwitter?: IExternaPlaylistState
    encryptToken: string | null
    thematic: Thematic | null
    thumbnails: string
    iframePath: string
    urlMP4: string
    authors: Author[]
    externPlayLists: ExternPlaylist[]
    subthematics: Thematic[]
    tags: Tag[]
    playlists: PlaylistPartial[]
    territories: Territory[]
    timezone: string | null
    temporaryId?: string
    subtitlePath?: string
    catalog: Catalog | null
    encoded: number
    maxpppAvailability?: number
    pendingDisponibility?: number
    uploaderEmail?: string
    type: EVideoType
    liveConfig: ILiveConfig | null
    youtubeURL?: string
    dailymotionURL?: string
    twitterURL?: string
    facebookURL?: string
    chapters?: ChapitrageData
    subtitle?: ISubtitle
}

export interface RawVideoResponse {
    data: RawVideo[]
    metadata: ResultMetadata
}
export interface RawVideo {
    id: string
    createdAt: string
    updatedAt: string
    userId: number
    title: string
    disponibility: number
    videoPath: string
    streams: number
    broadcastStart: string | null
    broadcastEnd: string | null
    description: string
    placeId: string
    duration: number
    lang: string
    externURL: string
    showAds: boolean
    ratingCSA: string
    refID: string
    externalID: string
    originalLang: string | null
    haveRights: string | null
    distributor: string
    otherAuthors: string
    enableOverlay: boolean
    displayText: string
    redirectURL: string
    startAt: number
    endAt: number
    textColor: string
    height: number
    maxpppAvailability?: number
    pubOnYoutube: IExternaPlaylistState
    pubOnDailymotion: IExternaPlaylistState
    pubOnFacebook: IExternaPlaylistState
    pubOnTwitter: IExternaPlaylistState
    encryptToken: string | null
    thematic: Thematic
    thumbnails: string
    iframePath: string
    urlMP4: string
    authors: Author[]
    subthematics: Thematic[]
    externPlayLists: ExternPlaylist[]
    tags: Tag[]
    territories: Territory[]
    playlists: PlaylistPartial[]
    timezone: string | null
    catalog: Catalog | null
    encoded: number
    uploaderEmail?: string
    type: EVideoType
    liveConfig: ILiveConfig | null
    youtubeURL?: string
    dailymotionURL?: string
    twitterURL?: string
    facebookURL?: string
}

export interface ReoderVideoResponse {
    order: number
    playlistId: number
    videoId: string
}

export type PlaylistWithVideoData = PlaylistVideo & {
    video: Video
}
export type PlaylistVideo = {
    order: number
    playlistId: Playlist['id']
    videoId: string
    playlist: PlaylistPartial
}

export interface CatalogResponse {
    id: number
    name: string
    lang: string
    thematic: Thematic
    subtitle: Subtitle
    incompatible_with: tIncompatibilityFields2[]
    createdAt: string
    updatedAt: string
}

export interface Territory {
    id: number
    name: string
    countryCode: string
}

export interface Statut {
    id: number
    name: string
}
export interface Localisation {
    id: number
    name: string
}
export interface MyPlaylistUpdateData {
    title?: string
    isFavorite?: boolean
    siteId?: number | null
    description?: string
    thumbnail?: string
    extraData?: Record<string, string>[]
}

export interface Thematic {
    id: number
    createdAt?: Date
    updatedAt?: Date
    name: string
    inventory?: boolean
    subThematics: Thematic[]
}
export interface Subtitle {
    auto: boolean
    display: boolean
    blockAuto: boolean
}

export type tIncompatibilityFields2 = 'field_2'
export interface Catalog {
    id: any
    createdAt: Date
    updatedAt: Date
    name: string
    lang: string
    subtitle: Subtitle | null
    thematic: Thematic | null
    incompatible_with: tIncompatibilityFields2[]
}
export interface Thumbnails {
    id: number
    createdAt?: Date
    updatedAt?: Date
    active: boolean
}
export interface addPlaylistVideResponse {
    order: number
    video: Video
    playlist: PlaylistPartial
}
export interface Author {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
}
export interface ResultMetadata {
    page: number
    results_per_page: number
    total: number
}

export enum EVideoStatuts {
    WAITING = 0,
    PUBLISHED = 1,
    DRAFT = 2,
    ENCODING = 3,
    SUSPENDED = 4,
    LIVE = 5
}

export interface Tag {
    id: number
    name: string
}

export interface TagResponse {
    id: number
    tag?: string
    name: string
}

export interface VideoFilter {
    title?: string
    catalog?: string
    author?: string
    thematic?: string
    disponibility?: string
    page: number
    limit: number
    order?: string
    createdAt?: string
    broadcastAt?: string
    placeId?: string
    type?: string
}
export interface ExternPlaylist {
    id: string
    name: string
    // company: string
    provider: ExternalPlaylistProvider
}

export type PlaylistPartial = Pick<Playlist, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'isChecked'>

export type Playlist = {
    id: string
    createdAt: Date
    updatedAt: Date
    name: string
    isFavorite: boolean
    nbrVideos: number
    site: Site | null
    thumbnails?: string
    extraData?: Record<string, string>[]
    description?: string
    isChecked?: boolean
}

export const defaultPlaylist: Playlist = {
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    isFavorite: false,
    nbrVideos: 0,
    site: null,
    thumbnails: '',
    extraData: [],
    description: '',
    isChecked: false
}

export type ExtraDataDecorated = {
    key: string
    value: string
    isDirtyKey?: boolean
    isDirtyValue?: boolean
}

export enum ErrorType {
    WARNING = 'warning',
    ERROR = 'error'
}

export interface ErrorChapterField {
    index: number | null
    error?: {
        type: ErrorType
        message: string
    }
}
export interface ErrorSubtitleField {
    index: number | null
    error?: {
        type: ErrorType
        message: string
    }
}

export type ChapitrageVideoEvo = {
    time: string | number
    title: string
    timeToSecond: number
}

type timeValue = {
    start: string | number
    end: string | number
}

export type SubtitleUpload = {
    time: timeValue
    text: string
    score?: number
}

export type ChapitrageVideoEvoData = {
    time: string | number
    title: string
}

export type ChapitrageData = {
    active: boolean
    data: ChapitrageVideoEvoData[]
}
export type ConfigLive = {
    startAt: string
    sendAlert: string[]
    email: number[]
}

export type LiveConfig = {
    liveId: string
    state: string
    rtmpKey: string
    urlRtmp: string
    vodAvailable: boolean
    config: ConfigLive
}

export type MarkChapitrage = {
    value: number
}

export interface MyPlaylistResponse {
    data: Playlist[]
    metadata: ResultMetadata
}

export interface MyPlaylistFilter {
    title?: string
    page?: number
    limit?: number
    order?: string
    sort?: string
    sites?: string
}

export interface VideoUpload {
    file: File
    temporaryId?: string
    disponibility?: EVideoStatuts
}
export type SubtitleType = {
    id?: number
    text: string
    time: Time
    score?: number
}
export type Time = {
    start: string
    end: string
}
export interface ISubtitle {
    active?: boolean
    type?: string
    status?: number
    subtitlePath?: string
    subtitleFile?: any
    data: SubtitleType[]
}
export interface IVideoPublish {
    id?: string
    temporaryId?: string
    description?: string
    catalog?: string
    userId: number
    thematic?: string
    tags?: string
    newTags?: string
    author?: string
    subthematics?: string
    title?: string
    placeId?: string
    lang?: string
    externURL?: string
    subtitlePath?: string
    showAds?: boolean
    ratingCSA?: number
    territories?: string
    refID?: string
    externalID?: string
    originalLang?: string
    haveRights?: string
    distributor?: string
    otherAuthors?: string
    enableOverlay?: boolean
    displayText?: string
    redirectURL?: string
    startAt?: number
    endAt?: number
    textColor?: string
    height?: number
    videoPath?: string
    streams?: string
    disponibility?: number
    pendingDisponibility?: number
    thumbnail?: number[]
    maxpppAvailability?: number
    pubOnYoutube?: IExternaPlaylistState
    pubOnDailymotion?: IExternaPlaylistState
    pubOnFacebook?: IExternaPlaylistState
    pubOnTwitter?: IExternaPlaylistState
    broadcastStart?: Date | null
    broadcastEnd?: Date | null
    timezone?: string | null
    thumbnails?: string
    authors?: string
    newAuthors?: string
    externPlayLists?: string
    encoded?: number
    subtitleFile?: string
    playlists?: string
    type: EVideoType
    liveConfig?: ILiveConfig | null
    createdAt?: Date
    updatedAt?: Date | null
    chapters?: ChapitrageData
    subtitle?: ISubtitle
}

export type IVideoEditPublish = Omit<IVideoPublish, 'id'> & {
    id: string
}

export interface ResMessage {
    message: string
}

export interface GetEncodedStatus {
    id: number
    temporaryId: string
    encoded: number
    disponibility: number
    pendingDisponibility: number | null
}
export type Soustitre = {
    active: boolean
    type: string
    subtitleFile: string
    data: SubtitleType
}
